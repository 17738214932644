<template>
  <ul class="be-avatar-group">
    <li v-for="(avatar, index) in visibleAvatars" :key="index">
      <slot name="avatar" v-bind="avatar">
        <be-avatar
          v-be-tooltip="{
            title: avatar.tooltip,
            disabled: index < visibleAvatars,
          }"
          v-bind="avatar"
        />
      </slot>
    </li>

    <li
      v-if="hiddenAvatars.length > 0"
      class="be-avatar-group-hidden-placeholder"
    >
      <be-avatar
        :id="hiddenPlaceholderId"
        :text="`+${hiddenAvatars.length}`"
        variant="primary"
      />

      <be-tooltip :target="hiddenPlaceholderId" custom-inner-class="mw-100">
        <slot name="hidden-placeholder-tooltip">{{ localHiddenTooltip }}</slot>
      </be-tooltip>
    </li>
  </ul>
</template>

<script>
import BeAvatar from "./BeAvatar.vue";
import { generateId } from "@/utils/id";

export default {
  components: {
    BeAvatar,
  },

  props: {
    avatars: {
      type: Array,
      required: true,
    },

    hiddenPlaceholderTooltip: {
      type: String,
      required: false,
      default: undefined,
    },

    visibleLimit: {
      type: Number,
      default: 3,
    },
  },

  data() {
    return {
      hiddenPlaceholderId: generateId("hidden-placeholder"),
    };
  },

  computed: {
    hiddenAvatars() {
      return this.avatars.slice(this.visibleLimit);
    },

    localHiddenTooltip() {
      return (
        this.hiddenPlaceholderTooltip ||
        this.hiddenAvatars.map((avatar) => avatar.text || avatar.alt).join("\n")
      );
    },

    visibleAvatars() {
      return this.avatars.slice(0, this.visibleLimit);
    },
  },
};
</script>
