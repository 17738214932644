<template>
  <be-timeline :events="eventsArray">
    <template #bullet>
      <be-skeleton
        :animation="animation"
        type="avatar"
        height="1.4em"
        width="1.4em"
      />
    </template>

    <template #content>
      <!-- Vertically align skeleton line with the bullet -->
      <div style="position: relative; top: 0.3em">
        <be-skeleton :animation="animation" width="50%" />
      </div>
    </template>
  </be-timeline>
</template>

<script>
export default {
  name: "BeSkeletonTimeline",

  props: {
    animation: {
      type: String,
      required: false,
      default: undefined,
    },

    events: {
      type: [Number, String],
      required: false,
      default: 5,

      validator: (value) => {
        return Number(value) >= 1;
      },
    },
  },

  computed: {
    eventsArray() {
      return Array.from({ length: this.events }, (_, i) => i);
    },
  },
};
</script>
