<template>
  <div class="be-excel-viewer">
    <div v-if="loading" class="d-flex justify-content-center p-3">
      <be-spinner />
    </div>

    <be-alert v-else-if="hasError" variant="warning" class="mb-0">
      {{ $t("components.shared.be_excel_viewer.error_alert") }}
    </be-alert>

    <template v-else>
      <be-alert variant="info">
        {{ $t("components.shared.be_excel_viewer.info_alert") }}
      </be-alert>

      <div v-if="sheets.length > 1" class="d-inline-block">
        <be-form-group
          :label="$t('components.shared.be_excel_viewer.sheet')"
          label-for="sheet"
        >
          <be-form-select id="sheet" v-model="activeSheet" :options="sheets" />
        </be-form-group>
      </div>

      <div class="table-responsive">
        <table v-if="data.length">
          <thead>
            <tr>
              <th v-for="(header, index) in data[0]" :key="index">
                {{ header }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(row, rowIndex) in data.slice(1)" :key="rowIndex">
              <td v-for="(cell, cellIndex) in row" :key="cellIndex">
                {{ cell }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
import { read, utils } from "xlsx";

export default {
  props: {
    fileUrl: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      workbook: null,
      sheets: [],
      activeSheet: null,
      data: [],
      loading: true,
      hasError: false,
    };
  },

  watch: {
    activeSheet() {
      this.loadSheetData();
    },
  },

  mounted() {
    this.loadFile();
  },

  methods: {
    async loadFile() {
      try {
        const response = await axios.get(this.fileUrl, {
          responseType: "arraybuffer",
        });
        const arrayBuffer = response.data;
        this.workbook = read(new Uint8Array(arrayBuffer), {
          type: "array",
        });
        this.sheets = this.workbook.SheetNames;
        this.activeSheet = this.sheets[0];
        this.loadSheetData();
      } catch (error) {
        this.handleError(error);
        this.hasError = true;
      } finally {
        this.loading = false;
      }
    },

    loadSheetData() {
      const sheet = this.workbook.Sheets[this.activeSheet];
      this.data = utils.sheet_to_json(sheet, { header: 1 });
    },
  },
};
</script>
