<template>
  <div class="file-uploader">
    <be-dropzone
      v-show="maxFiles === -1 || filesToKeep.length < maxFiles"
      ref="dropzone"
      :max-files="maxFiles"
      :accepted-file-types="acceptedTypes"
      :aws-url="awsUrl"
      :error="validationError"
      @new-empty-folder="handleNewEmptyFolder"
      @upload-started="uploadStarted"
      @upload-successful="uploadSuccessful"
      @upload-error="uploadError"
    >
      <template v-if="supportSelectFromArchive" #custom-button>
        <slot name="button" />
      </template>
    </be-dropzone>

    <template v-if="showFiles">
      <div
        v-for="file in displayedFiles"
        :key="fileId(file)"
        class="file-uploader-item mt-2"
        :data-status="file.upload_state"
      >
        <div
          class="d-flex align-items-center p-3 mb-2 border border-left-4 rounded text-wrap"
          :class="{
            'border-left-danger': file.upload_state === 'removed',
            'border-left-success': file.upload_state === 'saved',
            'border-left-warning': file.upload_state === 'added',
          }"
        >
          <div
            class="d-flex flex-grow-1 justify-content-between order-1 order-md-0 text-truncate"
          >
            <div class="d-flex align-items-center">
              <div class="d-none d-md-flex mr-2 align-items-center">
                <template v-if="file.thumbnail_url">
                  <be-avatar :src="file.thumbnail_url" size="sm" />
                </template>

                <template v-else>
                  <i :class="`fa-fw ${iconClass(file)}`" />
                </template>
              </div>

              <div class="text-dark text-wrap">
                <template v-if="manualSave && file.upload_state === 'removed'">
                  {{ $t("components.file_uploader.will_be_removed") }}
                </template>

                <template
                  v-else-if="file.record_id && file.record_type === 'Document'"
                >
                  <document-link
                    :document-id="file.record_id"
                    :filename="getName(file)"
                    :show-icon="false"
                  />
                </template>

                <template v-else>
                  {{ getName(file) }}
                </template>
              </div>
            </div>

            <div class="d-none d-md-flex align-items-center">
              <span class="font-weight-bold ml-2 mr-1">
                {{ getSize(file).value }}
              </span>
              {{ getSize(file).unit }}
            </div>
          </div>

          <div class="order-0 mr-2 order-md-1 mr-md-0 ml-md-3">
            <be-spinner v-if="file.upload_state === 'uploading'" />

            <be-button
              v-if="
                ['saved', 'added', 'cached'].includes(file.upload_state) ||
                pendingRemoval(file)
              "
              v-be-tooltip="$t('buttons.titles.remove')"
              :aria-label="$t('buttons.titles.remove')"
              variant="danger"
              size="sm"
              :loading="pendingRemoval(file)"
              icon="fa-times"
              @click="removeFile(file)"
            />

            <be-button
              v-else-if="file.upload_state == 'removed'"
              v-be-tooltip="$t('buttons.titles.restore')"
              :aria-label="$t('buttons.titles.restore')"
              variant="outline-secondary"
              size="sm"
              icon="fa-rotate-left"
              @click="restoreFile(file)"
            />
          </div>
        </div>
      </div>
    </template>

    <be-alert
      v-for="error in errors"
      :key="error.errorMessage"
      variant="danger"
      class="mb-2"
    >
      <span v-dompurify-html="error.errorMessage" />
    </be-alert>

    <file-input-field
      v-for="file in addedFiles"
      :key="`file-input-${fileId(file)}`"
      :name="inputName"
      :index="getIndex(file)"
      :file="file"
      :is-single-file="maxFiles == 1"
    />

    <div v-for="file in removedFiles" :key="file.id">
      <input type="hidden" :name="getRemovedName(file)" value="1" />

      <input
        type="hidden"
        :name="getRecordIdName(file)"
        :value="file.record_id"
      />
    </div>
  </div>
</template>

<script>
import {
  getExtensionForFilename,
  getClassNameForExtension,
} from "@/vendor/font-awesome-filetypes";
import BeDropzone from "./BeDropzone.vue";
import FileInputField from "./FileInputField.vue";
import Uuid from "@/mixins/uuid";
import isMobile from "is-mobile";

export default {
  components: {
    BeDropzone,
    FileInputField,
  },

  mixins: [Uuid],

  props: {
    name: {
      type: String,
      required: true,
    },

    awsUrl: {
      type: String,
      required: false,
      default: null,
    },

    noEdit: {
      type: Boolean,
      required: false,
      default: false,
    },

    files: {
      type: Array,
      required: false,
      default: () => [],
    },

    htmlForm: {
      type: Boolean,
      required: false,
      default: false,
    },

    attachmentName: {
      type: String,
      required: true,
    },

    maxFiles: {
      type: Number,
      required: false,
      default: -1,
    },

    acceptedTypes: {
      type: Array,
      required: false,
      default: () => [],
    },

    supportFolders: {
      type: Boolean,
      required: false,
    },

    supportSelectFromArchive: {
      type: Boolean,
      required: false,
      default: false,
    },

    clickableElement: {
      type: String,
      required: false,
      default: null,
    },

    validationError: {
      type: Boolean,
      required: false,
      default: false,
    },

    // If true, will show `Will be removed on save` while files are pending removal
    // If false, will show loading indicator
    manualSave: {
      type: Boolean,
      required: false,
      default: true,
    },

    showFiles: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  emits: [
    "uploading",
    "file-added",
    "file-removed",
    "file-restored",
    "new-empty-folder",
  ],

  data() {
    return {
      localFiles: this.cloneDeep(this.files),
      filesInUpload: [],
      errors: [],
    };
  },

  computed: {
    isMobile,

    // Added files are the files that are either added from the dropzone, the document picker, or
    // returned by a server response for files that were not saved due to e. g. validation issues.
    // The added files need to have corresponding input fields to be saved on the server.
    addedFiles() {
      return this.currentFiles.filter((file) => file.upload_state === "added");
    },

    // The removed files are files that are saved on the server but should be removed when saving the form.
    // Files can be marked for removal by the user or from a server response for an invalid form.
    // The removed files needs input fields if they previously were persisted.
    removedFiles() {
      return this.currentFiles.filter(
        (file) => file.upload_state === "removed"
      );
    },

    filesToKeep() {
      return this.currentFiles.filter(
        (file) => file.upload_state !== "removed"
      );
    },

    currentFiles() {
      if (this.htmlForm) {
        return this.localFiles;
      } else {
        return this.files;
      }
    },

    displayedFiles() {
      return this.currentFiles.concat(this.filesInUpload);
    },

    inputName() {
      return `${this.name}[${this.attachmentName}]`;
    },
  },

  watch: {
    filesInUpload: {
      handler(value) {
        let uploading = value.length > 0;
        this.$emit("uploading", uploading);
        this.documentSetDisabled(uploading);
      },

      deep: true,
    },
  },

  methods: {
    uploadStarted(file) {
      this.updateFile(file, this.filesInUpload);
    },

    uploadSuccessful(file) {
      const index = this.filesInUpload.findIndex(
        (existing) => existing.uuid === file.uuid
      );
      if (index > -1) {
        this.filesInUpload.splice(index, 1);
      }

      if (this.htmlForm) {
        this.updateFile(file, this.localFiles);
      } else {
        this.$emit("file-added", file);
      }

      this.clearOldErrors();
    },

    uploadError({ file, error, isUnknownError = false }) {
      const uuid = file?.upload?.uuid;
      const index = this.filesInUpload.findIndex(
        (existing) => existing.uuid && existing.uuid === uuid
      );

      if (index > -1) {
        this.filesInUpload.splice(index, 1);
      } else {
        console.warn("Could not find upload to remove on error");
      }

      let errorMessage = isUnknownError
        ? this.$t("components.file_uploader.errors.general", {
            filename: file.name,
          })
        : error;

      this.errors.push({
        errorMessage,
        date: Date.now(),
      });
      this.clearOldErrors();
    },

    handleNewEmptyFolder(folderPath) {
      this.$emit("new-empty-folder", folderPath);
    },

    pendingRemoval(file) {
      return !this.manualSave && file.upload_state === "removed";
    },

    // Clear errors that are more than 5 seconds old.
    clearOldErrors() {
      this.errors = this.errors.filter(
        (error) => Date.now() - error.date < 5000
      );
    },

    removeFile(file) {
      if (file.upload_state == "saved") {
        file.upload_state = "removed";

        if (this.htmlForm) {
          this.updateFile(file, this.localFiles);
        } else {
          this.$emit("file-removed", file);
        }
      } else {
        if (this.htmlForm) {
          const index = this.localFiles.findIndex(
            (existing) => existing === file
          );
          if (index > -1) {
            if (!this.htmlForm) {
              this.localFiles.splice(index, 1);
            }
          }
        } else {
          this.$emit("file-removed", file);
        }
      }
    },

    // Can only restore files that were originally saved,
    // not new files being added, therefore state is always 'saved'.
    restoreFile(file) {
      file.upload_state = "saved";

      if (this.htmlForm) {
        this.updateFile(file, this.localFiles);
      } else {
        this.$emit("file-restored", file);
      }
    },

    iconClass(file) {
      return `fal ${getClassNameForExtension(
        getExtensionForFilename(this.getName(file))
      )}`;
    },

    updateFile(file, files) {
      const index = this.findFileIndex(file, files);
      if (index === -1) {
        files.push(file);
      } else {
        files[index] = file;
      }
    },

    findFileIndex(file, files) {
      return files.findIndex(
        (existing) =>
          (file.id && file.id === existing.id) ||
          (file.uuid && file.uuid === existing.uuid)
      );
    },

    documentSetDisabled(value = true) {
      document
        .querySelectorAll("[data-file-uploader-disable]")
        .forEach((element) => (element.disabled = value));
    },

    generateLink(file) {
      if (!file.previewElement || !file.record_id) {
        return;
      }
      const [ref] = file.previewElement.querySelectorAll("[data-dz-name]");
      const url = this.url(`/documents/${file.record_id}`);

      const linkEl = document.createElement("a");
      linkEl.href = url;
      linkEl.innerText = ref.innerText;
      linkEl.target = "_blank";
      ref.replaceWith(linkEl);
    },

    thumbnail(file, dataUrl) {
      if (file.previewElement) {
        file.previewElement.classList.remove("dz-file-preview");
        let ref = file.previewElement.querySelectorAll(
          "[data-dz-thumbnail-bg]"
        );

        for (let j = 0, len = ref.length; j < len; j++) {
          let thumbnailElement = ref[j];
          thumbnailElement.innerHTML = "";
          thumbnailElement.alt = file.name;
          thumbnailElement.style.backgroundImage = 'url("' + dataUrl + '")';
        }

        return setTimeout(
          (function () {
            return function () {
              return file.previewElement.classList.add("dz-image-preview");
            };
          })(this),
          1
        );
      }
    },

    getIndex(file) {
      return this.currentFiles.findIndex((existing) => {
        return (
          file === existing ||
          (file.record_id && file.record_id === existing.record_id) ||
          (file.s3Signature && file.s3Signature === existing.s3Signature) ||
          (file.id && file.id === existing.id)
        );
      });
    },

    getRemovedName(file) {
      let index = this.getIndex(file);

      return `${this.name}[remove_${this.attachmentName}]`.replace(
        "_index_",
        index
      );
    },

    getRecordIdName(file) {
      const index = this.getIndex(file);

      return `${this.name}[id]`.replace("_index_", index);
    },

    isImage(url) {
      if (url) {
        return (
          getClassNameForExtension(getExtensionForFilename(url)) ==
          "fa-file-image"
        );
      }
      return false;
    },

    getName(file) {
      if (this.supportFolders && file?.fullPath) {
        return file.fullPath;
      }

      return file?.name || file?.filename || file?.metadata?.filename || "";
    },

    getSize(file) {
      let size = 0;
      if (file.size) {
        size = file.size;
      } else {
        size = file.metadata.size;
      }

      let exponent;
      let unit;
      let neg = size < 0;
      let units = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      if (neg) {
        size = -size;
      }

      if (size < 1) {
        return {
          value: neg ? -size : size,
          unit: "B",
        };
      }

      exponent = Math.min(
        Math.floor(Math.log(size) / Math.log(1000)),
        units.length - 1
      );
      size = (size / Math.pow(1000, exponent)).toFixed(2) * 1;
      unit = units[exponent];

      return {
        value: neg ? -size : size,
        unit: unit,
      };
    },

    fileId(file) {
      if (!file) {
        return null;
      } else if (file.record_id) {
        return `file-record-${file.upload_state}-${file.record_id}`;
      } else if (file.id) {
        return `file-id-${file.id}`;
      } else if (file.uuid) {
        return `file-uuid-${file.uuid}`;
      } else if (file.copy_from_id) {
        return `file-copy-from-${file.copy_from_id}`;
      }
    },
  },
};
</script>
