import { isValid, getWeek } from "date-fns";
import i18n from "@/i18n";

type LocaleString = "en-US" | "sv-SE";
type FormatDateInput = Date | string;
type DatepickerObject = {
  month?: number;
  quarter?: number;
  week?: number;
  year?: number;
};

const isDateObject = (date: FormatDateInput): date is Date => {
  return date instanceof Date;
};

const toDate = (date: FormatDateInput) => {
  return typeof date === "string" ? new Date(date) : date;
};

const isValidDate = (date: FormatDateInput) => {
  return isValid(toDate(date));
};

const formatDate = (date: FormatDateInput, locale: LocaleString = "en-US") => {
  if (!isValidDate(date)) {
    return date;
  }

  return new Intl.DateTimeFormat(locale, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(toDate(date));
};

const formatDateTime = (
  date: FormatDateInput,
  locale: LocaleString = "en-US"
) => {
  if (!isValidDate(date)) {
    return date;
  }

  return new Intl.DateTimeFormat(locale, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  }).format(toDate(date));
};

const formatDateRange = (
  startDate: FormatDateInput,
  endDate: FormatDateInput,
  locale: LocaleString = "en-US"
) => {
  const start = startDate ? formatDate(startDate, locale) : "";
  const end = endDate ? formatDate(endDate, locale) : "";

  return `${start} - ${end}`;
};

const formatTime = (date: FormatDateInput, locale: LocaleString = "en-US") => {
  if (!isValidDate(date)) {
    return date;
  }

  return new Intl.DateTimeFormat(locale, {
    hour: "2-digit",
    minute: "2-digit",
  }).format(toDate(date));
};

const formatWeek = (date: FormatDateInput, locale: LocaleString = "en-US") => {
  if (!isValid(date)) {
    return date;
  }

  const localeKey = locale.split("-")[0];
  const weekNumber = getWeek(new Date(date));
  const weekTranslation =
    i18n?.global?.messages[localeKey]?.components?.shared?.be_form_datepicker
      ?.week || "Week";
  const year = new Date(date).getFullYear();

  return `${weekTranslation} ${weekNumber}, ${year}`;
};

const formatMonth = (date: FormatDateInput, locale: LocaleString = "en-US") => {
  if (!isValid(date)) {
    return date;
  }

  return new Intl.DateTimeFormat(locale, {
    month: "long",
    year: "numeric",
  })
    .format(toDate(date))
    .replace(/^\w/, (c) => c.toUpperCase());
};

const formatQuarter = (date: FormatDateInput) => {
  if (!isValidDate(date)) {
    return date;
  }

  date = toDate(date);

  const quarter = Math.floor((date.getMonth() + 3) / 3);
  const year = date.getFullYear();

  return `Q${quarter} ${year}`;
};

const formatYear = (date: FormatDateInput, locale: LocaleString = "en-US") => {
  if (!isValidDate(date)) {
    return date;
  }

  return new Intl.DateTimeFormat(locale, {
    year: "numeric",
  }).format(toDate(date));
};

const parseDateNumber = (date: number): Date => {
  // 2021 -> new Date("2021-01-01")
  // 20210101 -> new Date("2021-01-01")
  // 202101 -> new Date("2021-01-01")

  const dateStr = date.toString();
  const year = parseInt(dateStr.slice(0, 4)) || 0;
  const month = parseInt(dateStr.slice(4, 6)) - 1 || 0;
  const day = parseInt(dateStr.slice(6, 8)) || 1;

  if (dateStr.length === 4) {
    return new Date(Date.UTC(year, 0, 1));
  } else if (dateStr.length === 6) {
    return new Date(Date.UTC(year, month, 1));
  } else if (dateStr.length === 8) {
    return new Date(Date.UTC(year, month, day));
  } else {
    return new Date(date);
  }
};

const parseDateObject = (date: DatepickerObject): Date => {
  // { year: 2021 } -> new Date("2021-01-01")
  // { year: 2021, month: 5 } -> new Date("2021-06-01")
  // { year: 2021, quarter: 1 } -> new Date("2021-01-01")
  if (!date) {
    return new Date();
  }

  if (date.week) {
    return new Date(Date.UTC(date.year, 0, 1 + date.week * 7));
  } else if (date.month) {
    return new Date(Date.UTC(date.year, date.month, 1));
  } else if (date.quarter) {
    return new Date(Date.UTC(date.year, date.quarter - 1, 1));
  } else {
    return new Date(Date.UTC(date.year, 0, 1));
  }
};

const setTimeToMidnight = (date: Date) => {
  if (!date || !isDateObject(date)) {
    return date;
  }

  return new Date(
    Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
  );
};

export default {
  isDateObject,
  isValidDate,
  formatDate,
  formatDateTime,
  formatDateRange,
  formatTime,
  formatWeek,
  formatMonth,
  formatQuarter,
  formatYear,
  parseDateNumber,
  parseDateObject,
  setTimeToMidnight,
};
