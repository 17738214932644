<template>
  <be-avatar-group :avatars="users" :visible-limit="visibleLimit">
    <template #avatar="avatar">
      <user-avatar :user="avatar" />
    </template>

    <template #hidden-placeholder-tooltip>
      <div
        v-for="(user, index) in hiddenUsers"
        :key="index"
        class="text-nowrap text-left"
      >
        {{ user }}
      </div>
    </template>
  </be-avatar-group>
</template>

<script>
import UserAvatar from "@/components/user/UserAvatar.vue";

export default {
  components: {
    UserAvatar,
  },

  props: {
    users: {
      type: Array,
      required: true,
    },

    visibleLimit: {
      type: Number,
      default: 3,
    },
  },

  computed: {
    hiddenUsers() {
      return this.users.slice(this.visibleLimit).map((user) => {
        if (user.name) {
          return user.name;
        } else if (typeof user === "number") {
          return this.getUserName(user);
        } else if (typeof user === "string") {
          return user;
        } else {
          return "";
        }
      });
    },
  },
};
</script>
