<template>
  <li role="presentation">
    <component
      :is="href ? 'a' : 'button'"
      v-bind="dataAttrs"
      ref="item"
      :href="href || null"
      :target="href ? target : null"
      :rel="href ? rel : null"
      :class="itemClasses"
      role="menuitem"
      :aria-disabled="disabled ? 'true' : null"
      :tabindex="disabled ? '-1' : null"
      :type="href ? null : 'button'"
      @click="onClick"
    >
      <slot />
    </component>
  </li>
</template>

<script>
export default {
  name: "BeDropdownItem",

  props: {
    active: {
      type: Boolean,
      required: false,
      default: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    href: {
      type: String,
      required: false,
      default: null,
    },

    itemClass: {
      type: [Array, Object, String],
      required: false,
      default: null,
    },

    rel: {
      type: String,
      required: false,
      default: null,
    },

    target: {
      type: String,
      required: false,
      default: "_self",
    },

    variant: {
      type: String,
      required: false,
      default: null,
    },
  },

  emits: ["click"],

  computed: {
    itemClasses() {
      const { active, disabled, itemClass, variant } = this;

      return [
        "dropdown-item",
        itemClass,
        {
          [`text-${variant}`]: variant && !(active || disabled),
          active,
          disabled,
        },
      ];
    },

    dataAttrs() {
      return Object.keys(this.$attrs).reduce((attrs, key) => {
        if (key.startsWith("data-")) {
          attrs[key] = this.$attrs[key];
        }

        return attrs;
      }, {});
    },
  },

  methods: {
    onClick(event) {
      this.$emit("click", event);
      this.closeDropdown();
    },

    closeDropdown() {
      const dropdown = this.$parent;

      // If parent is not "BeDropdown", check the parent's parent
      if (
        dropdown &&
        dropdown.$options.name !== "BeDropdown" &&
        typeof dropdown.$parent.hide === "function"
      ) {
        dropdown.$parent.hide();
      } else if (dropdown && dropdown.hide) {
        dropdown.hide();
      }
    },
  },
};
</script>
