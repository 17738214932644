<template>
  <div
    :id="id"
    :class="computedClasses"
    :data-drop-here-message="$t('components.shared.be_dropzone.drop_here')"
    tabindex="0"
    :aria-label="
      $t('components.shared.be_dropzone.aria_labels.file_upload_area')
    "
    @dragover.prevent="onDragOver"
    @dragleave.prevent="onDragLeave"
    @drop.prevent="handleFileDrop"
    @keyup.enter="onEnterPress"
  >
    <!-- Default content shown inside the dropzone -->
    <div
      v-if="!emptyBody"
      class="d-flex flex-wrap justify-content-center gap-2"
    >
      <div class="d-flex align-items-center gap-2">
        <i class="fal fa-lg fa-cloud-upload text-muted" />

        <p class="pb-0 mb-0">
          {{ $t("components.shared.be_dropzone.drag_and_drop_or") }}
        </p>
      </div>

      <div class="d-flex flex-wrap justify-content-center gap-2">
        <be-button variant="primary" class="py-0 px-1" @click="selectFile">
          {{
            isMobile
              ? $t("components.shared.be_dropzone.select_file_from_your_device")
              : $t("components.shared.be_dropzone.select_file")
          }}
        </be-button>

        <slot name="custom-button" />
      </div>
    </div>

    <!-- Shows allowed file types if any are specified -->
    <div v-if="emptyBody" class="text-center">
      <p
        v-if="acceptedFileTypes.length > 0"
        class="d-block m-0 small text-muted"
      >
        {{
          $t("components.shared.be_dropzone.allowed_types", {
            types: acceptedFileTypes.join(", "),
          })
        }}
      </p>
    </div>

    <div>
      <slot />
    </div>

    <!-- Hidden file input for selecting files -->
    <input
      :id="`${id}-file-input`"
      ref="fileInput"
      type="file"
      :accept="acceptedFileTypesAsMime.join(', ')"
      multiple
      hidden
      @change="handleFileSelect"
    />
  </div>
</template>

<script>
import s3Service from "@/services/s3-service";
import { ALLOWED_TYPES, ALLOWED_TYPES_MIME } from "@/constants/file-types";
import { generateId } from "@/utils/id";
import generateUuid from "@/utils/uuid";
import isMobile from "is-mobile";

export default {
  name: "BeDropzone",

  props: {
    acceptedFileTypes: {
      type: Array,
      required: false,
      default: () => [],
    },

    awsUrl: {
      type: String,
      required: false,
      default: "",
    },

    emptyBody: {
      type: Boolean,
      required: false,
      default: false,
    },

    error: {
      type: Boolean,
      required: false,
      default: false,
    },

    id: {
      type: String,
      required: false,
      default: () => generateId("be-dropzone"),
    },

    maxFiles: {
      type: Number,
      required: false,
      default: 100,
    },

    maxFileSize: {
      type: Number,
      required: false,
      default: 1024, // megabytes
    },

    onlyHoverStyling: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: [
    "new-empty-folder",
    "upload-started",
    "upload-successful",
    "upload-error",
    "uploads-completed",
  ],

  data() {
    return {
      dragHover: false, // Tracks if a file is being dragged over the dropzone
      fileInput: null, // Reference to the file input element
      isMobile: isMobile(),
    };
  },

  computed: {
    computedClasses() {
      return [
        "be-dropzone",
        {
          styled: this.onlyHoverStyling ? this.dragHover : true,
          "drag-hover": this.dragHover,
          error: this.error,
        },
      ];
    },

    acceptedFileTypesAsMime() {
      return this.acceptedFileTypes.map((type) => ALLOWED_TYPES[type]);
    },

    localAwsUrl() {
      return this.awsUrl || this.$currentCompany?.paths?.aws || "/aws";
    },
  },

  mounted() {
    this.fileInput = this.$refs.fileInput;
  },

  methods: {
    onDragOver() {
      // Set the drag hover state
      this.dragHover = true;
    },

    onDragLeave(event) {
      // Reset the drag hover state unless the event target is inside the dropzone
      if (!event.relatedTarget || !this.$el.contains(event.relatedTarget)) {
        this.dragHover = false;
      }
    },

    onEnterPress() {
      this.selectFile();
    },

    async handleFileDrop(event) {
      if (!event.dataTransfer) {
        return;
      }

      // Reset the drag hover state
      this.dragHover = false;

      // Get the dropped files
      let files = Array.from(event.dataTransfer.files);

      // Return if no files were dropped
      if (files.length === 0) {
        return;
      }

      // Check if browser supports folder upload, in which case we need to process the dropped items instead of files
      const { items } = event.dataTransfer;
      if (items && items.length && items[0].webkitGetAsEntry != null) {
        files = await this.processDroppedItems(items);
      }

      // Validate and upload the files
      await this.validateAndUploadFiles(files);
    },

    async processDroppedItems(items) {
      const files = [];

      const processPromises = Array.from(items).map(async (item) => {
        const entry = item.webkitGetAsEntry();

        if (entry.isFile) {
          files.push(item.getAsFile());
        } else if (entry.isDirectory) {
          const directoryFiles = await this.processDirectory(entry);
          directoryFiles.forEach((file) => files.push(file));
        }
      });

      await Promise.all(processPromises);

      return files;
    },

    async handleFileSelect() {
      // Validate and upload the selected files
      await this.validateAndUploadFiles(this.fileInput.files);

      // Clear the file input after the files have been uploaded
      this.fileInput.value = "";
    },

    async processDirectory(directoryEntry, parentPath = "") {
      const reader = directoryEntry.createReader();
      const path = [parentPath, directoryEntry.name].filter(Boolean).join("/");

      // Method to read all entries from the directory reader
      const readAllEntries = async () => {
        let entries = [];
        let readEntries;

        do {
          readEntries = await new Promise((resolve, reject) => {
            reader.readEntries(resolve, reject);
          });

          entries = entries.concat(readEntries);
        } while (readEntries.length > 0);

        return entries;
      };

      // Method to process a single file entry
      const processFileEntry = async (entry) => {
        const file = await new Promise((resolve) => entry.file(resolve));
        const modifiedFile = new File([file], file.name, {
          type: file.type,
        });
        modifiedFile.fullPath = `${path}/${entry.name}`;
        return modifiedFile;
      };

      const entries = await readAllEntries();
      const queuedFiles = [];

      // Handle empty folders
      if (entries.length === 0) {
        this.$emit("new-empty-folder", path);
        return queuedFiles;
      }

      for (const entry of entries) {
        if (entry.isFile) {
          const file = await processFileEntry(entry);
          queuedFiles.push(file);
        } else if (entry.isDirectory) {
          const directoryFiles = await this.processDirectory(entry, path);
          directoryFiles.forEach((file) => queuedFiles.push(file));
        }
      }

      return queuedFiles;
    },

    async validateAndUploadFiles(files) {
      // Filter out any invalid files
      const validFiles = this.filterValidFiles(files);

      // Check if the number of files exceeds the maximum allowed,
      // and if so, filter out the excess files and return an error
      // explaining that the maximum number of files for the current
      // upload has been exceeded.
      if (this.maxFiles > 0 && validFiles.length > this.maxFiles) {
        validFiles.splice(this.maxFiles);

        this.$emit("upload-error", {
          file: null,

          error: this.$t(
            "components.shared.be_dropzone.errors.max_files_exceeded_html",
            {
              maxFiles: this.maxFiles,
            },
            this.maxFiles
          ),
        });
      }

      // Map up the upload requests to an array of promises so they can run in parallel
      const uploadPromises = validFiles.map((file) => this.uploadFile(file));

      // Array to store the successful uploads
      const successfulUploads = [];

      try {
        // Wait for all the uploads to complete
        await Promise.all(
          uploadPromises.map(async (promise, index) => {
            try {
              const result = await promise;

              this.$emit("upload-successful", result);
              successfulUploads.push(result);
            } catch {
              this.$emit("upload-error", {
                file: validFiles[index],
                error: "error",
                isUnknownError: true,
              });
            }
          })
        );
      } catch (error) {
        this.handleError(error);
      }

      // Emit an event indicating that all uploads have been completed and return the successful uploads
      this.$emit("uploads-completed", successfulUploads);
    },

    filterValidFiles(files) {
      // Validation rules for file type and size
      const validations = [
        { rule: this.validateFileType, options: this.acceptedFileTypesAsMime },
        { rule: this.validateFileSize, options: this.maxFileSize },
      ];

      // Run the validation rules for each file and return the valid ones
      return Array.from(files).filter((file) => {
        return validations.every((validation) => {
          return validation.rule(file, validation.options);
        });
      });
    },

    validateFileType(file, acceptedTypes) {
      // Filter out files starting with a dot (e.g. .DS_Store)
      if (file.name.startsWith(".")) {
        return false;
      }

      if (acceptedTypes.length === 0 || acceptedTypes.includes(file.type)) {
        return true;
      } else {
        // Emit error message
        this.$emit("upload-error", {
          file,

          error: this.$t(
            "components.shared.be_dropzone.errors.invalid_file_type_html",
            {
              file: file.name,
              type: ALLOWED_TYPES_MIME[file.type] || file.type,
              allowedTypes: this.acceptedFileTypes.join(", "),
            }
          ),
        });

        return false;
      }
    },

    validateFileSize(file, maxSize) {
      if (file.size <= maxSize * 1024 * 1024) {
        return true;
      } else {
        // Emit error message
        this.$emit("upload-error", {
          file,

          error: this.$t(
            "components.shared.be_dropzone.errors.invalid_file_size_html",
            {
              file: file.name,
              size: (file.size / 1024 / 1024).toFixed(2),
              maxSize,
            }
          ),
        });

        return false;
      }
    },

    async uploadFile(file) {
      // Assign a unique UUID to the file
      file.uuid = generateUuid();

      // Emit an event indicating the upload has started
      this.$emit("upload-started", this.formatFileResponse(file, "uploading"));

      try {
        // Attempt to upload the file to S3 and return the response
        if (this.flipperFlag("upload_endpoint")) {
          return await s3Service.uploadFileViaApp(file);
        } else {
          return await s3Service.uploadFile(file, this.localAwsUrl);
        }
      } catch (error) {
        // Emit an error event if the upload fails
        this.$emit("upload-error", { file, error });
      }
    },

    formatFileResponse(file, state) {
      return {
        uuid: file.uuid,
        upload_state: state,
        relative_path: file.fullPath || file.webkitRelativePath,

        metadata: {
          filename: file.name,
          mime_type: file.type,
          size: file.size,
        },
      };
    },

    selectFile() {
      // Trigger the file input click event
      this.fileInput.click();
    },
  },
};
</script>
